<template>
  <section class="HistoricalPage">
    <div class="HistoricalPage__Table">
      <div class="HistoricalPage__Title">
        <h1>{{$t('labs_historical')}}</h1>
        <LabsCounter :pointsNum="userProfile.points"/>
      </div>
      <div class="HistoricalPage__Table--Wrapper">
        <table >
          <thead>
            <tr>
              <th>{{$t('date')}}</th>
              <th>{{$t('labs_action_concept')}}</th>
              <th>LABs</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(o,i) in historical.objects">
              <td>{{$filters.formatDateFull(o.creationDate)}}</td>
              <td>{{formatType(o.type,i,o.customConcept)}}</td>
              <td class="lab-cell">{{o.points}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="HistoricalPage__Pagination">
          <paginate
            v-if="historical.pages > 1"
            v-model="page"
            :page-count="historical.pages"
            :click-handler="handlePageChange"
            :container-class="'WatPagination'"
            prev-class="item-disable"
            next-class="item-disable"
            :margin-pages="3"
              />
      </div>
    </div>
  </section>

</template>

<script>
  import Button from '~/components/Button';
  import store from '@/store';
  import { mapGetters } from 'vuex';
  import Paginate from "vuejs-paginate-next";
  import LabsCounter from '@/components/LabsCounter';


  import {
    USER_FETCH_HISTORICAL,
    USER_AUTH_LOGOUT,
  } from '@/store/user.module.ts';

  export default {
    name: 'HistoricalPage',

    components: {
      Button,
      LabsCounter,
      Paginate,
    },

    data() {
      return {
        historical:{},
        page:1,
      };
    },

    computed: {
      ...mapGetters([
        'theme',
        'origin',
        'isUser',
        'isClient',
        'isModerator',
        'isObservator',
        'communityInfo',
        'userProfile'
      ]),
    },
    methods: {
      handlePageChange(page){
        this.fetchHistorical()
      },
      handleLogout() {
        store.dispatch( USER_AUTH_LOGOUT );
        this.$router.push( { name: 'login', params: { origin: this.$route.params.origin, }, } );
      },
      async fetchHistorical(){
        let data = {
          page: this.page-1,
          pageSize: 10,
        }
        await store.dispatch(USER_FETCH_HISTORICAL, {data})
        .then((data) => {
          this.historical = data
        });
      },
      formatType: function(_type,index, concept){
        let type = ""
        switch (_type) {
          case "EL_REDEEM":
            let redeemType = this.historical.objects[index].redeemType
            if(redeemType == "AMAZON") redeemType = "Amazon"
            else if(redeemType == "PAYPAL") redeemType = "PayPal"
            else if(redeemType == "RAFFLE") redeemType = "Sorteo"
            else if(redeemType == "CUSTOM") redeemType = this.historical.objects[index].sortName
            type = "Canjeo de " + redeemType
            break;
          case "EL_ACTIVITY_PARTICIPATED":
            type = this.$t('community_welcome_activity_participate')
            break;
          case "EL_COMMENT_APPRECIATED":
            type = this.$t('community_welcome_gold_medal')
            break;
          case "EL_COMMUNITY_ACTIVE":
            type = this.$t('community_welcome_be_active')
            break;
          case "EL_MODERATOR_CUSTOM":
            type = this.$t('role_moderator')+": "+concept
            break;
        }
        return type
      },
    },

    async mounted(){
      this.fetchHistorical()
    }

  }
</script>

<style lang="scss">
.Panel--historical{
  .container-fluid{
      background: white;
  }
}
</style>

<style scoped lang="scss">
.HistoricalPage{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  color: #181818;

  &__Title{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h1{
      margin-right: 20px;
    }
  }
  &__Table{
    padding: 2.5rem;
    width:100%;
    &--Wrapper{
      margin-bottom: 20px;
    }
  }
  &__Pagination{
    width: 100%;
    margin: auto;
  }
  @media screen and (max-width: 991px){
      max-width: 700px;
  }
  @media screen and ( max-width: 768px ) {
    margin: 0;
    max-width: unset;
    margin-bottom: 2rem;
  }
}
table {
  border-collapse: separate;
  border-spacing: 0;
  width:100%;
  text-align: center;
  thead{
    background: var(--primary-color);
    color: white;
  }
  .lab-cell{
      color: #f8b656;
      font-weight: 700;
  }

  tr{
    border-top: 1px solid #c3c3c3;
    th{
      border-top: 1px solid #bbb;
    }
    th,td {
      border-right: 1px solid #bbb;
      border-bottom: 1px solid #bbb;
      padding: 5px;
      &:first-child{
        border-left: 1px solid #bbb;
      }
    }
    &:first-child th:first-child {
      border-top-left-radius: 6px;
    }
    &:first-child th:last-child {
      border-top-right-radius: 6px;
    }
  }
  tbody tr{
    &:nth-child(odd){
      background: #f2f2f2;
    }
  }
}
</style>
